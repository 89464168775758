import React from 'react';

interface IReviewCommentContext {
  productId: number;
  canCommentAsBa: boolean;
}

export const ReviewCommentContext = React.createContext<IReviewCommentContext>({
  productId: null,
  canCommentAsBa: false,
});

interface OwnProps extends IReviewCommentContext {
  children: React.ReactNode;
}

const ReviewCommentProvider = (props: OwnProps) => {
  const { productId, canCommentAsBa } = props;
  return (
    <ReviewCommentContext.Provider
      value={{
        productId,
        canCommentAsBa,
      }}
    >
      {props.children}
    </ReviewCommentContext.Provider>
  );
};

export default ReviewCommentProvider;
