import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { XCircle } from 'react-feather';

import useZoom from './hooks/useZoom';

const ZoomContainer = styled.div<{ isZoomed?: boolean }>`
  width: 100%;
  cursor: ${props => (props.isZoomed ? 'zoom-out' : 'zoom-in')};
`;

const ClearButton = styled.button`
  z-index: 20;
  position: fixed;
  left: 16px;
  top: 29px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  svg {
    fill: ${props => props.theme.colors.neutral5};
    stroke: #ffffff;
  }
`;

const Zoomable = ({
  onZoom,
  onUnzoom,
  children,
}: {
  onZoom?: () => void;
  onUnzoom?: () => void;
  children: React.ReactNode;
}) => {
  const { isZoomed, handleZoomChange } = useZoom({
    onZoom,
    onUnzoom,
  });

  if (!isZoomed) {
    return (
      <ZoomContainer onClick={() => handleZoomChange(!isZoomed)}>
        {children}
      </ZoomContainer>
    );
  }

  return (
    <ReactModal
      isOpen
      shouldCloseOnOverlayClick
      onRequestClose={() => handleZoomChange(false)}
      style={{
        overlay: {
          zIndex: 20,
          backgroundColor: 'rgba(64, 72, 90, 0.75)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'zoom-out',
        },
        content: {
          position: 'relative',
          background: 'transparent',
          border: '0',
          overflow: 'hidden',
          borderRadius: '0',
          padding: '0',
          inset: '0',
          maxWidth: '90vw',
          maxHeight: '90vh',
          cursor: 'default',
        },
      }}
    >
      <ClearButton onClick={() => handleZoomChange(false)}>
        <XCircle size={24} />
      </ClearButton>
      {children}
    </ReactModal>
  );
};

export default Zoomable;
