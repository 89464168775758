import React, { useContext } from 'react';
import { Button, Text, Dropdown } from 'seedly-component-library';
import EngagementBarLayout from 'components/post/EngagementBarLayout';
import { MoreHorizontal } from 'react-feather';
import Reactions from 'components/post/Reactions';
import BookmarkButton from 'components/post/BookmarkButton';
import { TextEditorContext } from 'components/post/TextEditor/TextEditorContext';
import { UPVOTER_TYPE, SAVE_TYPE } from 'constants/app';
import {
  Comment,
  CommentableType,
  CommentRelationMap,
  ParentCommentTypes,
} from 'entity/comment/commentTypes';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { PostCommentContext } from '../CommentContext/PostCommentContext';

interface OwnProps {
  comment: Comment;
  commentMenu: {
    title: string | React.ReactNode;
    value: string;
    onClick?: () => void;
  }[];
  shareMenu: {
    title: string | React.ReactNode;
    value: string;
    onClick?: () => void;
  }[];
  onReplyClick: any;
  commentableType: CommentableType;
}

const CommentEngagement = (props: OwnProps) => {
  const {
    comment,
    commentableType,
    commentMenu,
    shareMenu,
    onReplyClick,
  } = props;

  const { isPromoGroup } = useContext(PostCommentContext);
  const { onEditorClickFromOutside } = useContext(TextEditorContext);

  // for tracking purpose
  const isParentComment = ParentCommentTypes.includes(commentableType);
  const trackingCommentable = isParentComment
    ? commentableType
    : CommentRelationMap[commentableType];

  return (
    <EngagementBarLayout
      viewCount={undefined}
      spaceSize="gap-4"
      bookmarkButton={
        <BookmarkButton
          contentId={comment.id}
          saved={comment.bookmarked}
          type={SAVE_TYPE.ANSWER}
          commentableId={comment.commentableId}
          commentableType={commentableType}
          onClickTrack={() => {
            mixpanel.track(trackedEvents.CommentThread, {
              param: 'bookmark',
              category: trackingCommentable.toLocaleLowerCase(),
            });
          }}
          isText
        />
      }
      replyButton={
        <Text
          intent="secondary"
          variant="titlesm"
          onClick={() => {
            onReplyClick();
            if (onEditorClickFromOutside) onEditorClickFromOutside.current();
          }}
          hoverable
        >
          Reply
        </Text>
      }
      shareButton={
        <Dropdown
          items={shareMenu}
          handleSelectedItemchange={item => {
            item.selectedItem.onClick();
          }}
          menuAlign="left"
          button={
            <Text
              intent="secondary"
              variant="titlesm"
              data-testid="button-share-opinion"
              onClick={() => {
                mixpanel.track(trackedEvents.CommentThread, {
                  param: 'share',
                  category: trackingCommentable.toLocaleLowerCase(),
                });
              }}
              hoverable
            >
              Share
            </Text>
          }
        />
      }
      menuButton={
        <Dropdown
          items={commentMenu}
          handleSelectedItemchange={item => {
            item.selectedItem.onClick();
          }}
          menuAlign="right"
          button={
            <Button
              size="sm"
              variant="ghost"
              data-testid="menu-options"
              onClick={() => {
                mixpanel.track(trackedEvents.CommentThread, {
                  param: 'three_dot_menu',
                  category: trackingCommentable.toLocaleLowerCase(),
                });
              }}
              icon={<MoreHorizontal />}
            />
          }
        />
      }
      reactionButton={
        !isPromoGroup ? (
          <Reactions
            id={comment.id}
            commentableId={comment.commentableId}
            commentableType={commentableType}
            upvoted={comment.upvoted}
            upvoteCount={comment.upvotesCount}
            type={UPVOTER_TYPE.COMMENT}
          />
        ) : null
      }
    />
  );
};

export default CommentEngagement;
