import { useDispatch, useSelector } from 'react-redux';
import {
  openReportModal,
  openLoginModal,
} from 'app/dispatcher/modalDispatcher';
import { RootState } from 'reducer';
import { deleteCommentRequest } from 'entity/comment/commentRedux';
import { REPORT_MODAL, TYPES } from 'app/constants/report';
import ProfileActions from 'app/reducer/profileRedux';
import ConfirmationModal from 'app/components/post/ConfirmationModal';
import { Comment, CommentableType } from 'entity/comment/commentTypes';
import { useIsSignedIn } from 'entity/user/userHooks';

const handleDeleteComment = (onDelete, onCancel = () => {}) => {
  ConfirmationModal({
    title: 'Are you sure you want to delete comment?',
    content: 'Comment will be permanently removed.',
    okText: 'Confirm',
    cancelText: 'Cancel',
  }).then(onDelete, onCancel);
};

const useCommentMenu = ({
  comment,
  commentableType,
  onEditClick = () => {},
}: {
  comment: Comment;
  commentableType: CommentableType;
  onEditClick: () => void;
}) => {
  const {
    createdByCurrentUser,
    createdByCurrentBusinessAccount,
    businessAccount,
    commentableId,
  } = comment;

  const dispatch = useDispatch();

  const userProfile = useSelector((state: RootState) => state.profile.user);
  const isSignedIn = useIsSignedIn();

  const ownOptions = [
    {
      title: 'Delete',
      value: 'delete',
      onClick: () =>
        handleDeleteComment(() => {
          dispatch(
            deleteCommentRequest({
              commentId: comment.id,
              commentableType,
              commentableId,
            }),
          );
        }),
    },
    {
      title: 'Edit',
      value: 'edit',
      onClick: () => {
        onEditClick();
        if (createdByCurrentBusinessAccount) {
          dispatch(ProfileActions.setBusinessProfile({ ...businessAccount }));
        } else {
          dispatch(ProfileActions.setBusinessProfile({ ...userProfile }));
        }
      },
    },
  ];

  const publicOptions = [
    {
      title: 'Report',
      value: 'report',
      onClick: () => {
        const modalData = {
          commentId: comment.id,
          id: commentableId,
          data: REPORT_MODAL[TYPES.ANSWER],
          commentableType,
        };
        if (!isSignedIn) {
          sessionStorage.setItem('new-report', JSON.stringify(modalData));
          return dispatch(openLoginModal());
        }
        dispatch(openReportModal(modalData));
      },
    },
  ];

  if (createdByCurrentUser || createdByCurrentBusinessAccount) {
    return ownOptions;
  }
  return publicOptions;
};

export default useCommentMenu;
