import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { getCommentsRequest } from 'entity/comment/commentRedux';
import { useIsSignedIn } from 'entity/user/userHooks';
import { CommentableType } from 'entity/comment/commentTypes';
import {
  useGetPaginationByType,
  useCommentById,
} from 'entity/comment/commentHooks';
import { PostCommentContext } from '../../CommentContext/PostCommentContext';
import CommentItem from '../../CommentItem';

interface OwnProps {
  totalCommentCount: number;
  commentableId: number;
  commentableType: CommentableType;
  commentShareLink: string;
}

const ChildCommentList = (props: OwnProps) => {
  const {
    totalCommentCount = 0,
    commentableId,
    commentableType,
    commentShareLink,
  } = props;

  const dispatch = useDispatch();

  const isSignedIn = useIsSignedIn();
  const commentPaginations = useGetPaginationByType(
    commentableType,
    commentableId,
  );
  const commentById = useCommentById();
  const { isPromoGroup } = useContext(PostCommentContext);
  const { pagination, ids } = commentPaginations;

  // if no comment
  const currentPage = pagination.currentPage === 0 ? 1 : pagination.currentPage;
  const fetchedCommentCount = pagination ? pagination.per * currentPage : 0;
  const commentLeft = totalCommentCount - fetchedCommentCount;
  const blockLoadMore = !isSignedIn && fetchedCommentCount > 1;

  const commentList = ids.map(id => {
    const comment = commentById[id];
    return (
      <CommentItem
        key={id}
        comment={comment}
        commentableType={commentableType}
        commentShareLink={commentShareLink}
      />
    );
  });

  if (commentList.length === 0 && totalCommentCount === 0) return null;

  const LoadMoreButton = (
    <span
      className="text-xs font-bold text-neutral-500 hover:text-blue-500 underline cursor-pointer"
      onClick={() => {
        // if is the first comment
        dispatch(
          getCommentsRequest({
            commentableType,
            commentableId,
            page: fetchedCommentCount === 1 ? 1 : pagination.currentPage + 1,
            shuffleComment: isPromoGroup,
            // sort from oldest to newest in child comment
            sort: {
              by: 'updated_at',
              dir: 'asc',
            },
          }),
        );
      }}
    >
      View {commentLeft} other comments
    </span>
  );

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="list">{commentList}</div>
      {commentLeft > 0 && !blockLoadMore && LoadMoreButton}
    </div>
  );
};

export default ChildCommentList;
