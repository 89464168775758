const getTotalWordCount = editorState => {
  if (!editorState) return 0;
  const plainText = editorState.getCurrentContent
    ? editorState.getCurrentContent().getPlainText('')
    : editorState;
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, ' ').trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray.length : 0;
};

export default getTotalWordCount;
