import React from 'react';
import { useDispatch } from 'react-redux';
import { openLoginModal } from 'dispatcher/modalDispatcher';
import LoginButton from 'components/auth/LoginButton';
import { LOGIN_MODAL, TYPES } from 'constants/login';

interface OwnProps {
  totalCommentCount: number;
}

const SignUpBlockCard = (props: OwnProps) => {
  const { totalCommentCount = 1 } = props;
  const dispatch = useDispatch();

  return (
    <div className="card h-[350px] absolute z-10 sign-up-card-bg shadow-none">
      <div className="flex flex-col gap-5 items-center w-full mt-10">
        <div className="flex flex-col w-full gap-5 items-center">
          <div className="flex flex-col w-full gap-2 items-center">
            <p className="text-center text-base font-bold">
              Read {totalCommentCount} other comments with a Seedly account{' '}
            </p>
            <p className="text-center text-neutral-600">
              You will also enjoy exclusive benefits and get access to members
              only features.
            </p>
          </div>
          <div className="flex flex-col w-full gap-2 items-center">
            <LoginButton variant="google" />
            <LoginButton variant="facebook" />
            <LoginButton variant="apple" />
          </div>
        </div>
        <p className="text-neutral-500">
          Sign up or login with an email{' '}
          <span
            className="text-blue-500 underline cursor-pointer"
            onClick={() =>
              dispatch(openLoginModal(LOGIN_MODAL[TYPES.UNLOCK_ANSWER]))
            }
          >
            here
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignUpBlockCard;
