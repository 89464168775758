import React from 'react';
import { convertDateFormat } from 'app/utils/convertDateFormat';
import { generateCredentialText } from 'app/utils/formatCredentials';
import { Tooltip } from '@component-lib';

import { imageUrl } from 'theme';
import { isNewToSeedly } from 'entity/user/userUtils';
import Image from 'next/image';
import UserPassportBase from './UserPassportBase';

const badgeSizes = {
  'avatar-2': [14, 20],
  'avatar-6': [14, 20],
  'avatar-8': [14, 20],
  'avatar-10': [14, 20],
  'avatar-12': [18, 24],
  'avatar-16': [40, 48],
};

const UserPassport = props => {
  const {
    profile,
    createdAt,
    updatedAt,
    credential,
    anonymousType,
    businessAccount,
    avatarSize,
    postedGroup,
    joinGroupButton,
  } = props;

  let verifiedBadge;
  let badgeUrl;
  let badgeDescription;
  let badge;
  let imgSrc;
  let displayName;
  let levelTitle;
  let profileSlug;
  let credentialText;

  if (businessAccount) {
    const { picture, name } = businessAccount;
    displayName = name;
    imgSrc = picture;
    verifiedBadge = (
      <img
        loading="lazy"
        src={imageUrl.claimed}
        style={{ marginLeft: '4px' }}
        width={12}
        height={12}
        alt=""
      />
    );
    credentialText = '';
  } else {
    const { image, name: userName, slug, badges: profileBadges, level } =
      profile || {};
    imgSrc =
      image && image.indexOf('facebook') > -1 ? `${image}?type=square` : image;
    displayName = !anonymousType ? userName : 'Anonymous Poster';
    profileSlug = !anonymousType && slug;

    if (profileBadges?.length > 0) {
      // profile badges is sorted by latest on first
      const expiredDate = new Date(profileBadges[0].expiresAt);
      const currentDate = new Date();
      if (
        expiredDate.getMonth() === currentDate.getMonth() &&
        expiredDate.getFullYear() === currentDate.getFullYear()
      ) {
        badgeUrl = imageUrl.badge;
        badgeDescription = 'Monthly Top Contributor';
      }
    }

    if (isNewToSeedly(profile)) {
      badgeUrl = imageUrl.plant;
      badgeDescription = 'New to Seedly';
    }

    if (profile.officialAccount) {
      badgeUrl = imageUrl.claimed;
      badgeDescription = 'Official Seedly Account';
    }

    levelTitle = level ? `Level ${level}` : '';
    credentialText = generateCredentialText(credential);
  }

  if (badgeUrl && badgeDescription) {
    const badgeSize = badgeSizes[avatarSize] || [14, 20];
    badge = (
      <Tooltip
        showToolTip
        placement="bottom"
        renderTriggerButton={() => (
          <Image src={badgeUrl} width={badgeSize[0]} height={badgeSize[1]} />
        )}
        renderToolTipBody={() => <div>{badgeDescription}</div>}
      />
    );
  }

  const dateText =
    createdAt && updatedAt && convertDateFormat(createdAt, updatedAt);

  return (
    <UserPassportBase
      imgSrc={imgSrc}
      name={displayName}
      credentialText={credentialText}
      profileSlug={profileSlug}
      levelTitle={levelTitle}
      createdAtTitle={dateText}
      anonType={anonymousType}
      badge={badge}
      verifiedBadge={verifiedBadge}
      avatarSize={avatarSize}
      postedGroup={postedGroup}
      joinGroupButton={joinGroupButton}
    />
  );
};

export default UserPassport;
