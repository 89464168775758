import React, { useState, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducer';
import { useFormik } from 'formik';
import { Image, XCircle } from 'react-feather';
import * as Sentry from '@sentry/nextjs';
import {
  editorStateToFormattedText,
  emptyEditorContentState,
  contentEditedStateWithImage,
} from 'app/utils/markdownFormat';
import { openLoginModal } from 'dispatcher/modalDispatcher';
import { LOGIN_MODAL, TYPES } from 'constants/login';
import getTotalWordCount from 'utils/getWordCountDraftJs';
import { Message, Checkbox } from 'seedly-component-library';
import { profileImages } from 'app/seedly-component-library/avatar-new/helper';
import Avatar from 'seedly-component-library/avatar-new';
import Button from 'seedly-component-library/button-new';
import { profileImage } from 'theme';
import { useIsSignedIn } from 'entity/user/userHooks';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import TextEditor from 'components/post/TextEditor';
import ProfileSelect from 'components/profile/ProfileSelect';
import { getBusinessAccounts } from 'selectors/authSelectors';
import { ReviewCommentContext } from 'components/comment/CommentContext/ReviewCommentContext';
import { PostCommentContext } from 'components/comment/CommentContext/PostCommentContext';
import { convertFileToBase64, verifyFileSize } from 'app/utils/imageHelper';
import { ANON_TYPE } from 'constants/app';
import {
  postCommentRequest,
  updateCommentRequest,
} from 'entity/comment/commentRedux';
import {
  UpdateCommentRequestPayload,
  CommentableType,
  Comment,
  ParentCommentTypes,
  CommentRelationMap,
} from 'entity/comment/commentTypes';

const getInitialState = editComment => {
  if (editComment) {
    return {
      commentText: contentEditedStateWithImage(editComment.commentText),
      picture: editComment.picture,
      picturePreview: editComment.picture,
      isAnonymous: editComment.isAnonymous,
    };
  }

  return {
    commentText: emptyEditorContentState('comment'),
    picture: null,
    picturePreview: null,
    isAnonymous: false,
  };
};

interface OwnProps {
  commentableId: number;
  commentableType: CommentableType;
  editComment?: Comment;
  setEditComment?: (props: any) => any;
  expandPostCommentForm?: boolean;
}

const PostCommentForm = (props: OwnProps) => {
  const {
    commentableId,
    editComment = null,
    expandPostCommentForm = false,
    setEditComment = () => {},
    commentableType,
  } = props;

  const dispatch = useDispatch();

  const [isFocus, setIsFocus] = useState(editComment || expandPostCommentForm);

  const { canCommentAsAnon } = useContext(PostCommentContext);
  const isSignedIn = useIsSignedIn();
  const userProfile = useSelector(
    (state: RootState) => state.profile.selectedProfile,
  );

  const isParentComment = ParentCommentTypes.includes(commentableType);

  const { name, image, isBusinessProfile, id } = userProfile;
  const businessAccountId = isBusinessProfile ? id : null;

  const inputFile = useRef(null);
  const formik = useFormik({
    initialValues: getInitialState(editComment),
    onSubmit: (values, formikBag) => {
      const commentText = editorStateToFormattedText(
        values.commentText.getCurrentContent(),
      );
      const { picture } = values;

      if (!isSignedIn) {
        sessionStorage.setItem(
          'new-comment',
          JSON.stringify({
            commentableType,
            commentableId,
            comment: commentText,
            picture,
            business_account_id: businessAccountId,
          }),
        );

        dispatch(openLoginModal(LOGIN_MODAL[TYPES.CREATE_COMMENT]));
        return;
      }

      const submitComment = () => {
        if (editComment) {
          const params: UpdateCommentRequestPayload = {
            commentableType,
            commentableId,
            comment: commentText,
            commentId: editComment.id,
            is_anonymous: values.isAnonymous,
          };

          if (businessAccountId) {
            params.business_account_id = businessAccountId;
          }

          // if there is changes is editing picure
          if (editComment.picture !== picture && picture !== undefined) {
            params.picture = picture;
          }

          dispatch(
            updateCommentRequest({
              ...params,
              onSuccess: () => {
                formikBag.resetForm();
              },
            }),
          );
        } else {
          dispatch(
            postCommentRequest({
              commentableType,
              commentableId,
              comment: commentText,
              picture,
              business_account_id: businessAccountId,
              is_anonymous: values.isAnonymous,
              onSuccess: () => {
                formikBag.resetForm();
              },
            }),
          );
        }
      };

      submitComment();
    },
  });

  // handling businessAccount
  const { productId, canCommentAsBa } = useContext(ReviewCommentContext);
  const businessAccounts = useSelector(getBusinessAccounts);
  const disallowBaComment =
    !canCommentAsBa &&
    businessAccountId &&
    !editComment &&
    commentableType === 'Review';

  const { commentText, picturePreview, isAnonymous } = formik.values;

  const stopSubmit =
    getTotalWordCount(commentText) === 0 ||
    formik.isSubmitting ||
    disallowBaComment;

  // for tracking purpose
  const trackingCommentable = isParentComment
    ? commentableType
    : CommentRelationMap[commentableType];

  const isSelectorEnabled =
    commentableType === 'Review' || commentableType === 'ReviewComment';

  return (
    <div className="flex flex-col gap-3 w-full items-center">
      <div className="flex gap-2 w-full">
        <div className="mt-0.5">
          {businessAccounts.length > 0 && !editComment && isSelectorEnabled ? (
            <ProfileSelect
              anonType={isAnonymous ? ANON_TYPE.QUESTION : ''}
              productId={productId}
            />
          ) : (
            <Avatar
              src={
                isAnonymous
                  ? profileImages[ANON_TYPE.QUESTION].img
                  : image || profileImage.defaultImage
              }
              name={name}
            />
          )}
        </div>
        <div className="flex flex-col gap-3 w-full items-center">
          <TextEditor
            height={isFocus ? 'fit-content' : '38px'}
            minHeight={isFocus ? '66px' : '38px'}
            editorState={commentText}
            onChange={textValue =>
              formik.setFieldValue('commentText', textValue)
            }
            placeholder="What are your thoughts?"
            data-testid={
              editComment
                ? `${commentableType}-edit-comment-box`
                : `${commentableType}-comment-box`
            }
            onEditorClick={() => {
              setIsFocus(true);
              mixpanel.track(trackedEvents.CommentThread, {
                param: isParentComment
                  ? 'reply_parent_comment'
                  : 'reply_child_comment',
                category: trackingCommentable.toLocaleLowerCase(),
              });
            }}
            haveCommentTips={isFocus}
          />
          {isFocus && (
            <div className="flex flex-col gap-2 w-full">
              {picturePreview && (
                <div className="h-fit w-fit relative">
                  <XCircle
                    className="fill-neutral-500 stroke-neutral-200 absolute z-10 -right-0.5 -top-1 cursor-pointer"
                    size={20}
                    onClick={() => {
                      formik.setFieldValue('picturePreview', null);
                      formik.setFieldValue('picture', null);
                    }}
                  />
                  <img
                    className="object-cover rounded-lg border-[2px] border-solid border-neutral-400"
                    alt="preview"
                    height="40"
                    width="70"
                    src={picturePreview}
                  />
                </div>
              )}
              <div className="flex justify-between w-full">
                <div className="flex gap-2 items-center">
                  <Image
                    className="cursor-pointer"
                    size={24}
                    onClick={() => {
                      inputFile.current.click();
                    }}
                  />
                  {canCommentAsAnon && (
                    <Checkbox
                      name="isAnonymous"
                      onChange={e => {
                        formik.setFieldValue('isAnonymous', e.target.checked);
                      }}
                      checked={isAnonymous}
                      labelSpace="xxs"
                    >
                      <p>Reply anonymously</p>
                    </Checkbox>
                  )}
                </div>
                {editComment ? (
                  <div className="flex gap-2 items-center">
                    <Button
                      onClick={() => setEditComment(false)}
                      variant="ghost"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setEditComment(false);
                        formik.handleSubmit();
                      }}
                      disabled={stopSubmit}
                      data-testid="button-update-comment"
                    >
                      Update
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="primary"
                    disabled={stopSubmit}
                    onClick={
                      formik.isSubmitting
                        ? () => {}
                        : () => formik.handleSubmit()
                    }
                    data-testid="button-post-comment"
                    isLoading={formik.isSubmitting}
                  >
                    Post
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        id="file"
        ref={inputFile}
        onClick={() => {
          Sentry.addBreadcrumb({
            category: 'Image',
            message: 'User uploading image for comments',
            level: Sentry.Severity.Info,
          });
          // Allows user to upload same file
          inputFile.current.value = null;
        }}
        onChange={async e => {
          const imageFile = e.target.files[0];
          const isAcceptedFileSize = verifyFileSize(imageFile);
          if (imageFile && isAcceptedFileSize) {
            try {
              const base64image = await convertFileToBase64(imageFile);
              const base64ToURL = URL.createObjectURL(imageFile);
              formik.setFieldValue('picture', base64image);
              formik.setFieldValue('picturePreview', base64ToURL);
            } catch (error) {
              Sentry.captureMessage('User failed to upload Image for comments');
              Sentry.captureException(error);
              formik.setFieldValue('picture', null);
              formik.setFieldValue('picturePreview', null);
            }
          } else {
            Message.error('Please upload an image smaller than 5MB');
          }
        }}
        accept="image/*"
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default PostCommentForm;
