import { useSelector } from 'react-redux';
import { RootState } from 'reducer';
import orderBy from 'lodash/orderBy';
import { Selector, createSelector } from 'reselect';
import head from 'lodash/head';
import { Comment, CommentableType } from './commentTypes';

const getById: Selector<RootState, Record<number, Comment>> = (
  state: RootState,
) => state.comment.byId;

const getJsonLdComments = createSelector([getById], comments => {
  const commentResult = {
    acceptedComment: {},
    suggestedComments: [],
  };

  const commentsArray = Object.values(comments);
  if (commentsArray.length === 0) return commentResult;

  commentResult.acceptedComment = head(
    orderBy(commentsArray, ['upvotesCount', 'createdAt'], ['desc', 'desc']),
  );
  commentResult.suggestedComments = commentsArray.filter(comment => {
    return comment !== commentResult.acceptedComment;
  });

  return commentResult;
});

const useGetJsonLdComments = () => {
  return useSelector(getJsonLdComments);
};

const useGetPaginationByType = (
  commentableType: CommentableType,
  commentableId: number,
) => {
  const pagination = useSelector((state: RootState) => {
    return state.comment.byType[commentableType][commentableId];
  });

  if (pagination) {
    return pagination;
  }

  return {
    ids: [],
    pagination: {
      per: 0,
      currentPage: 0,
      totalPage: 0,
      totalCount: 0,
    },
  };
};

const useCommentById = () => {
  return useSelector(getById);
};

const useUserComments = () => {
  return useSelector((state: RootState) => state.comment.userComments);
};

const useCommentApiStatus = () => {
  return useSelector((state: RootState) => state.comment.api);
};

export {
  useGetJsonLdComments,
  useGetPaginationByType,
  useCommentById,
  useUserComments,
  useCommentApiStatus,
};
