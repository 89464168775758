import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LikeButton, Text } from 'app/seedly-component-library';
import { VOTE_TYPE } from 'app/entity/comment/commentTypes';
import { UPVOTER_TYPE } from 'app/constants/app';
import { voteOpinion } from 'app/dispatcher/opinionDispatcher';
import { voteReview } from 'app/dispatcher/reviewDispatcher';
import { openLoginModal } from 'app/dispatcher/modalDispatcher';
import { LOGIN_MODAL, TYPES } from 'app/constants/login';
import get from 'lodash/get';
import { votePostRequest } from 'entity/post/postRedux';
import { voteCommentRequest } from 'entity/comment/commentRedux';

const ReactionButton = props => {
  const { type, upvoteCount, upvoted, onClick } = props;

  switch (type) {
    case UPVOTER_TYPE.REVIEW:
      return (
        <>
          <LikeButton
            onClick={onClick}
            count={upvoteCount}
            isSelected={upvoted}
            customPrefix={
              <Text variant="titlemd" intent="success">
                Helpful &#8226;
              </Text>
            }
          />
        </>
      );
    case UPVOTER_TYPE.OPINION:
      return (
        <LikeButton
          onClick={onClick}
          count={upvoteCount}
          isSelected={upvoted}
          data-testid="thumbs-up"
        />
      );
    case UPVOTER_TYPE.COMMENT:
      return (
        <LikeButton
          onClick={onClick}
          count={upvoteCount}
          isSelected={upvoted}
          data-testid="thumbs-up"
        />
      );
    case UPVOTER_TYPE.POST:
      return (
        <LikeButton
          onClick={onClick}
          count={upvoteCount}
          isSelected={upvoted}
          data-testid="thumbs-up"
        />
      );
    default:
      return null;
  }
};

const Reactions = props => {
  const {
    upvoted,
    upvoteCount,
    type,
    id,
    commentableId,
    commentableType,
  } = props;

  const isSignedIn = useSelector(state =>
    get(state.auth.session, 'isSignedIn', false),
  );
  const dispatch = useDispatch();

  const handleVotePress = () => {
    let mixpanelAc;

    const voteType = upvoted ? VOTE_TYPE.UNVOTE : VOTE_TYPE.UPVOTE;

    if (!isSignedIn) {
      let params = { id, voteType: VOTE_TYPE.UPVOTE, type };
      if (type === UPVOTER_TYPE.COMMENT) {
        mixpanelAc = 'vote_comment';
        params = {
          commentId: id,
          commentableId,
          commentableType,
          voteType: VOTE_TYPE.UPVOTE,
          type,
        };
      }

      if (type === UPVOTER_TYPE.POST) {
        mixpanelAc = 'vote_post';
      }

      sessionStorage.setItem('new-vote', JSON.stringify(params));

      if (type === UPVOTER_TYPE.COMMENT) {
        mixpanelAc = 'vote_comment';
      }

      if (type === UPVOTER_TYPE.POST) {
        mixpanelAc = 'vote_post';
      }

      return dispatch(
        openLoginModal({ ...LOGIN_MODAL[TYPES.VOTE], ac: mixpanelAc }),
      );
    }

    if (type === UPVOTER_TYPE.COMMENT) {
      mixpanelAc = 'vote_comment';
      dispatch(
        voteCommentRequest({
          commentId: id,
          commentableId,
          commentableType,
          voteType,
        }),
      );
    } else if (type === UPVOTER_TYPE.OPINION) {
      dispatch(voteOpinion({ id, voteType }));
    } else if (type === UPVOTER_TYPE.REVIEW) {
      dispatch(voteReview({ id, voteType }));
    } else if (type === UPVOTER_TYPE.POST) {
      dispatch(votePostRequest({ id, voteType }));
      mixpanelAc = 'vote_post';
    }
  };

  return (
    <ReactionButton
      type={type}
      upvoteCount={upvoteCount}
      upvoted={upvoted}
      onClick={() => handleVotePress()}
    />
  );
};

export default Reactions;
