import React from 'react';
import { Eye } from 'react-feather';
import clsx from 'clsx';

interface OwnProps {
  viewCount?: number;
  commentsCount?: number;
  commentButton?: React.ReactNode;
  bookmarkButton?: React.ReactNode;
  shareButton?: React.ReactNode;
  reactionButton?: React.ReactNode;
  menuButton?: React.ReactNode;
  replyButton?: React.ReactNode;
  spaceSize?: string;
}

const EngagementBarLayout = (props: OwnProps) => {
  const {
    viewCount,
    commentsCount = 0,
    commentButton,
    bookmarkButton,
    shareButton,
    reactionButton,
    menuButton,
    replyButton,
    spaceSize,
  } = props;

  return (
    <div className="flex w-full justify-between">
      <div className={clsx('flex items-center', spaceSize || 'gap-1')}>
        {viewCount !== undefined && (
          <div className="flex items-center">
            <div className="flex items-center gap-2">
              <Eye size={20} className="text-neutral-500" />
              <p className="text-xs text-neutral-500">{viewCount}</p>
            </div>
            <div className="vertical-divider ml-3" />
          </div>
        )}
        {replyButton}
        {commentButton && (
          <div className="flex items-center">
            {commentButton}
            <p className="text-xs" data-testid="comment-count">
              {commentsCount}
            </p>
          </div>
        )}
        {bookmarkButton}
        {shareButton}
        {menuButton}
      </div>
      {reactionButton}
    </div>
  );
};

export default EngagementBarLayout;
