import React from 'react';
import { useSelect } from 'downshift';
import { ChevronDown } from 'react-feather';
import { profileImages } from '../avatar-new/helper';
import * as S from './styles';
import Text from '../text';
import Avatar from '../avatar-new';
import Space from '../space';

const ProfileSelect = props => {
  const {
    items,
    handleSelectedItemchange = () => {},
    defaultSelectedItem,
    menuAlign = 'right',
    anonType = '',
  } = props;

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    initialSelectedItem: defaultSelectedItem,
    onSelectedItemChange: handleSelectedItemchange,
  });

  const { name, image, id } = selectedItem;

  return (
    <S.DropdownWrapper>
      <S.ButtonWrapper {...getToggleButtonProps()}>
        <div className="flex gap-2 items-center w-full">
          <Avatar
            src={anonType === 'question' ? profileImages.question.img : image}
            name={name}
          />
          <ChevronDown size={16} />
        </div>
      </S.ButtonWrapper>
      <S.StyledProfileList menuAlign={menuAlign} {...getMenuProps()}>
        {isOpen && (
          <>
            <S.TopLabel>
              <Text variant="caption">Answering and commenting as:</Text>
            </S.TopLabel>
            {items.map((item, index) => {
              const isSelected = item.id === id;

              const listItem = (
                <S.StyledProfileListItem
                  isSelected={isSelected}
                  key={index}
                  {...getItemProps({ item, index })}
                >
                  <Space
                    size="xs"
                    width="100%"
                    align={item.secondaryName ? 'start' : 'center'}
                  >
                    <Avatar src={item.image} name={item.name} />
                    <Space
                      size="none"
                      direction="column"
                      align="start"
                      width="100%"
                    >
                      <Text intent={isSelected ? 'inverted' : 'default'}>
                        {item.name}
                      </Text>
                      <Text variant="caption" intent="secondary">
                        {item.secondaryName}
                      </Text>
                    </Space>
                  </Space>
                </S.StyledProfileListItem>
              );

              if (item.sectionTitle) {
                return (
                  <>
                    <div className="horizontal-divider mt-3" />
                    <S.SectionLabel>
                      <Text variant="titlesm">{item.sectionTitle}</Text>
                    </S.SectionLabel>
                    {listItem}
                  </>
                );
              }
              return listItem;
            })}
          </>
        )}
      </S.StyledProfileList>
    </S.DropdownWrapper>
  );
};

export default ProfileSelect;
