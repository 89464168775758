import dayjs from 'dayjs';

const isNewToSeedly = profile => {
  if (!profile) return false;

  const {
    questionsCount = 0,
    commentsCount = 0,
    joinedAt,
    createdAt,
  } = profile;

  if (joinedAt || createdAt) {
    const createdDay = dayjs(joinedAt || createdAt);
    const today = dayjs(new Date());
    const count = questionsCount + commentsCount;
    if (today.diff(createdDay, 'month') <= 3 && count < 6) {
      return true;
    }
  }

  return false;
};

export { isNewToSeedly };
