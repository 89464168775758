import React from 'react';
import { useDispatch } from 'react-redux';
import { openPostModalForSharing } from 'app/dispatcher/modalDispatcher';
import { Message } from 'app/seedly-component-library';
import { Link, Linkedin, Facebook, Users } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';

const CustomTelegramIcon = () => (
  <svg
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m8 16c4.4193 0 8-3.5807 8-8 0-4.4193-3.5807-8-8-8-4.4193 0-8 3.5807-8 8 0 4.4193 3.5807 8 8 8zm-4.3393-8.1733 7.7133-2.974c0.358-0.12934 0.6707 0.08733 0.5547 0.62866l6e-4 -6.6e-4 -1.3133 6.1873c-0.0973 0.4387-0.358 0.5453-0.72267 0.3387l-2-1.474-0.96466 0.9293c-0.10667 0.1067-0.19667 0.1967-0.40334 0.1967l0.142-2.0354 3.7067-3.3487c0.1613-0.142-0.036-0.222-0.2487-0.08067l-4.5806 2.884-1.9747-0.616c-0.42867-0.136-0.438-0.42867 0.09067-0.63533z"
      fill="#40485A"
    />
  </svg>
);

const CustomWhatsappIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m13.663 2.3264c-1.5007-1.5015-3.5017-2.3264-5.6324-2.3264-4.3876 0-7.9618 3.5581-7.9656 7.9259 0 1.3989 0.36659 2.7598 1.0616 3.9648l-1.1265 4.1093 4.2234-1.1024c1.1647 0.631 2.4745 0.9656 3.8072 0.9656h0.00382c4.3876 0 7.9618-3.5581 7.9656-7.9297-0.0038-2.1174-0.8325-4.1093-2.337-5.607z"
      clipRule="evenodd"
      fill="#40485A"
      fillRule="evenodd"
    />
    <path
      d="m11.662 9.5876c-0.1985-0.09883-1.1761-0.57781-1.3594-0.64623-0.1833-0.06462-0.31694-0.09884-0.44677 0.09884-0.13365 0.19767-0.51551 0.64623-0.63007 0.77548-0.11456 0.13305-0.23294 0.14825-0.43151 0.04941-0.19856-0.09883-0.84009-0.30791-1.6-0.98455-0.59188-0.52459-0.98902-1.1746-1.1074-1.3723-0.11456-0.19767-0.01146-0.30411 0.08782-0.40295 0.08783-0.08743 0.19857-0.23188 0.29786-0.34592 0.09928-0.11404 0.13365-0.19767 0.19856-0.33072 0.06492-0.13305 0.03437-0.24709-0.01527-0.34593-0.04964-0.09883-0.44678-1.0758-0.6148-1.4711-0.16038-0.38774-0.32458-0.33452-0.44678-0.33832-0.11455-0.0076-0.24821-0.00761-0.38186-0.00761s-0.34749 0.04942-0.53079 0.24709c-0.18329 0.19768-0.69498 0.67665-0.69498 1.6536 0 0.97696 0.71408 1.9159 0.81336 2.049 0.09929 0.13305 1.4014 2.1325 3.3986 2.9916 0.47351 0.2053 0.84391 0.327 1.1341 0.4182 0.47732 0.152 0.90882 0.1292 1.2525 0.0798 0.3818-0.057 1.1761-0.479 1.3441-0.9427 0.1642-0.4638 0.1642-0.85913 0.1146-0.94276s-0.1833-0.13305-0.3819-0.23189z"
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

const useShareMenu = shareUrl => {
  const dispatch = useDispatch();

  const shareToGroup = {
    title: (
      <div className="flex items-center gap-2">
        <Users size={16} />
        <p className="text-sm font-semibold hover:text-blue-500">
          Share To Group
        </p>
      </div>
    ),
    value: 'share-group',
    onClick: () => {
      dispatch(openPostModalForSharing(shareUrl));
    },
  };

  const facebook = {
    title: (
      <FacebookShareButton url={shareUrl}>
        <div className="flex items-center gap-2">
          <Facebook size={16} />
          <p className="text-sm font-semibold hover:text-blue-500">Facebook</p>
        </div>
      </FacebookShareButton>
    ),
    value: 'share-facebook',
  };

  const linkedin = {
    title: (
      <LinkedinShareButton url={shareUrl}>
        <div className="flex items-center gap-2">
          <Linkedin size={16} />
          <p className="text-sm font-semibold hover:text-blue-500">Linkedin</p>
        </div>
      </LinkedinShareButton>
    ),
    value: 'share-linkedin',
  };

  const telegram = {
    title: (
      <TelegramShareButton url={shareUrl}>
        <div className="flex items-center gap-2">
          <CustomTelegramIcon />
          <p className="text-sm font-semibold hover:text-blue-500">Telegram</p>
        </div>
      </TelegramShareButton>
    ),
    value: 'share-telegram',
  };

  const whatsapp = {
    title: (
      <WhatsappShareButton url={shareUrl}>
        <div className="flex items-center gap-2">
          <CustomWhatsappIcon />
          <p className="text-sm font-semibold hover:text-blue-500">Whatsapp</p>
        </div>
      </WhatsappShareButton>
    ),
    value: 'share-link',
  };

  const copyToClipboard = {
    title: (
      <CopyToClipboard text={shareUrl}>
        <div className="flex items-center gap-2">
          <Link size={16} />
          <p className="text-sm font-semibold hover:text-blue-500">Copy Link</p>
        </div>
      </CopyToClipboard>
    ),
    value: 'share-link',
    onClick: () => {
      Message.success('Link copied');
    },
  };

  return [
    shareToGroup,
    facebook,
    linkedin,
    telegram,
    whatsapp,
    copyToClipboard,
  ];
};

export default useShareMenu;
