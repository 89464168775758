import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { getCommentsRequest } from 'entity/comment/commentRedux';
import { useIsSignedIn } from 'entity/user/userHooks';
import {
  CommentableType,
  CommentSortBy,
  CommentSortDir,
} from 'entity/comment/commentTypes';
import {
  useGetPaginationByType,
  useCommentById,
} from 'entity/comment/commentHooks';
import { useRouter } from 'next/router';
import useIsMobile from 'utils/hooks/useIsMobile';
import useIsNativeApp from 'utils/hooks/useIsNativeApp';

import { PostCommentContext } from '../CommentContext/PostCommentContext';
import { CommunityPageContext } from '../../../pageContents/CommunityContent/context/CommunityPageContext';

import CommentItemWithChild from '../CommentItemWithChild';
import SignUpBlockCard from '../SignUpBlockCard';

interface OwnProps {
  totalCommentCount: number;
  commentableId: number;
  commentableType: CommentableType;
  commentShareLink: string;
  childCommentableType: CommentableType;
  enableTruncateOnFirstComment?: boolean;
  sort?: {
    by: CommentSortBy;
    dir: CommentSortDir;
  };
}

const CommentList = (props: OwnProps) => {
  const {
    totalCommentCount = 0,
    commentableId,
    commentableType,
    childCommentableType,
    commentShareLink = '',
    enableTruncateOnFirstComment,
    sort = {
      by: 'updated_at',
      dir: 'desc',
    },
  } = props;

  const dispatch = useDispatch();

  const isSignedIn = useIsSignedIn();
  const commentPaginations = useGetPaginationByType(
    commentableType,
    commentableId,
  );
  const commentById = useCommentById();
  const router = useRouter();
  const isMobile = useIsMobile();
  const isNativeApp = useIsNativeApp();

  const { isPromoGroup } = useContext(PostCommentContext);
  const { rcid } = router.query;
  const { pageType } = useContext(CommunityPageContext);

  const { pagination, ids } = commentPaginations;

  const allowPostNumber = totalCommentCount > 6 ? 5 : 3;

  const commentIds = isSignedIn ? [...ids] : [...ids.slice(0, allowPostNumber)];

  // if no comment
  const currentPage = pagination.currentPage === 0 ? 1 : pagination.currentPage;
  const fetchedCommentCount = pagination ? pagination.per * currentPage : 0;
  const commentLeft = totalCommentCount - fetchedCommentCount;
  const blockLoadMore = !isSignedIn && fetchedCommentCount > 1;

  let allowedCommentCount = 0;
  const commentList = commentIds.map((id, index) => {
    allowedCommentCount += 1;
    const comment = commentById[id];

    let highlightReplies = false;
    if (index === 0 && rcid) {
      highlightReplies = !!comment.comments.find(child => {
        return child.id === Number(rcid);
      });
    }

    const isRestricted = !isSignedIn && allowedCommentCount === allowPostNumber;

    return (
      <CommentItemWithChild
        key={id}
        comment={comment}
        commentableType={commentableType}
        childCommentableType={childCommentableType}
        isRestricted={isRestricted}
        // only have truncate on first comment on the list
        enableTruncate={enableTruncateOnFirstComment && commentIds.length === 1}
        restrictedCard={
          <SignUpBlockCard
            totalCommentCount={totalCommentCount - allowPostNumber + 1}
          />
        }
        commentShareLink={commentShareLink}
        highlightReplies={highlightReplies}
      />
    );
  });

  if (commentList.length === 0 && totalCommentCount === 0) return null;

  const fetchMoreComment = () => {
    dispatch(
      getCommentsRequest({
        commentableType,
        commentableId,
        page: fetchedCommentCount === 1 ? 1 : pagination.currentPage + 1,
        shuffleComment: isPromoGroup,
        sort,
      }),
    );
  };

  const LoadMoreButton = (
    <span
      className="text-xs font-bold text-blue-500 underline cursor-pointer"
      onClick={() => {
        if (isNativeApp && pageType) {
          window.location.href = commentShareLink;
          return;
        }

        // for post inside trending tab only
        if (!isSignedIn && pageType === 'trending') {
          router.push(commentShareLink);
          return;
        }

        // for post on mobile, open new link instead
        if (isMobile && pageType) {
          router.push(commentShareLink);
          return;
        }

        fetchMoreComment();
      }}
    >
      View {commentLeft} other comments
    </span>
  );

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="list gap-4">{commentList}</div>
      {commentLeft > 0 && !blockLoadMore && LoadMoreButton}
    </div>
  );
};

export default CommentList;
