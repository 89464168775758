import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MarkdownTruncate, Zoomable } from 'seedly-component-library';
import { ANON_TYPE } from 'constants/app';
import { RootState } from 'app/reducer';

import PostCommentForm from 'components/comment/PostCommentForm';
import UserPassport from 'components/profile/UserPassport';
import PostEncouragement from 'components/post/PostEncouragement';

import {
  Comment,
  CommentableType,
  ParentCommentTypes,
} from 'entity/comment/commentTypes';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import CommentEngagement from './CommentEngagement';
import useCommentMenu from './useCommentMenu';
import OriginalPostLink from './OriginalPostLink';
import useShareMenu from '../../../utils/hooks/useShareMenu';
import { PostCommentContext } from '../CommentContext/PostCommentContext';

export interface OwnProps {
  comment: Comment;
  commentableType: CommentableType;
  // Restrict content if user does not sign in
  restrictedCard?: React.ReactNode;
  // Link Component to original Post in merge questions
  isRestricted?: boolean;
  enableTruncate?: boolean;
  commentShareLink?: string;
  onReplyClick?: () => any;
}

// Display parent comment and nested child comment
const CommentItem = (props: OwnProps) => {
  const {
    comment,
    commentableType,
    restrictedCard = null,
    isRestricted = false,
    enableTruncate,
    commentShareLink,
    onReplyClick = () => {},
  } = props;

  const router = useRouter();

  if (!comment) return null;

  const { id, commentText, picture, pictureOriginal } = comment;

  const [isPictureZoomed, togglePictureZoom] = useState(false);
  const [shouldTruncate, toogleTruncate] = useState(false);
  const [editComment, setEditComment] = useState(null);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const profile = useSelector((state: RootState) => state.profile.user);

  const { postId, isParentQuestion } = useContext(PostCommentContext);
  const { pcid, rcid, rid } = router.query;

  const ref = useRef<HTMLDivElement>(null);

  const showPostLink = postId !== comment.commentableId && isParentQuestion;

  useEffect(() => {
    if (Number(pcid) === comment.id || Number(rcid) === comment.id) {
      setIsHighlighted(true);
      if (ref.current && !rid) {
        const offsetTop = ref.current.offsetTop - 150;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, [pcid, rcid, rid, comment.id]);

  useEffect(() => {
    if (commentText?.length > 300 && enableTruncate) {
      toogleTruncate(true);
    }
  }, [commentText, enableTruncate]);

  // share link
  const shareLinkAppend = ParentCommentTypes.includes(commentableType)
    ? `&pcid=${id}`
    : `&rcid=${id}`;

  const shareMenu = useShareMenu(commentShareLink + shareLinkAppend);
  const commentMenu = useCommentMenu({
    comment,
    commentableType,
    onEditClick: () => setEditComment(comment),
  });

  // if user does not sign in
  const formattedCommentText = isRestricted
    ? `${commentText.substring(0, 100)}...`
    : commentText;

  return (
    <div
      className={clsx(
        'w-full rounded ease-in duration-100',
        isHighlighted ? 'bg-blue-100' : 'bg-white',
      )}
      ref={ref}
      onMouseEnter={() => setIsHighlighted(false)}
      data-testid="comment-item"
    >
      <div className="flex flex-col w-full gap-2 relative">
        {editComment ? (
          <PostCommentForm
            commentableType={commentableType}
            commentableId={editComment.commentableId}
            editComment={editComment}
            setEditComment={setEditComment}
            expandPostCommentForm
          />
        ) : (
          <>
            <UserPassport
              profile={comment.createdByCurrentUser ? profile : comment.user}
              businessAccount={comment.businessAccount}
              createdAt={comment.createdAt}
              updatedAt={comment.updatedAt}
              credential={comment.user && comment.user.defaultCredential}
              anonymousType={comment.isAnonymous && ANON_TYPE.ANSWER}
              actionByText="Posted"
            />
            <div
              className={clsx(
                'flex flex-col relative gap-2 w-full pl-10',
                isRestricted ? 'h-[340px] lg:h-[300px]' : 'auto',
              )}
            >
              {showPostLink && !isRestricted && (
                <OriginalPostLink question={comment.question} />
              )}
              <div className="h-full w-full relative break-words ">
                <MarkdownTruncate
                  text={formattedCommentText}
                  shouldTruncate={shouldTruncate}
                  toogleTruncate={toogleTruncate}
                  truncateLine={3}
                  extendedClass="comment-body"
                />
              </div>
              {picture && !isRestricted && (
                <Zoomable
                  onZoom={() => {
                    togglePictureZoom(true);
                  }}
                  onUnzoom={() => {
                    togglePictureZoom(false);
                  }}
                >
                  <div className="py-2 relative w-full ">
                    <img
                      src={isPictureZoomed ? pictureOriginal : picture}
                      alt="comment"
                      className="rounded-xl object-fill relative border-solid border border-neutral-400"
                    />
                  </div>
                </Zoomable>
              )}
              {comment.user?.firstCommentId === id &&
                !comment.createdByCurrentUser &&
                dayjs(new Date()).diff(dayjs(comment.createdAt), 'day') < 8 && (
                  <PostEncouragement name={comment.user?.name} type="comment" />
              )}
              {comment.user?.firstReplyId === id &&
                !comment.createdByCurrentUser &&
                dayjs(new Date()).diff(dayjs(comment.createdAt), 'day') < 8 && (
                  <PostEncouragement
                    name={comment.user?.name}
                    type="comment reply"
                  />
              )}
              {!isRestricted && (
                <CommentEngagement
                  comment={comment}
                  commentMenu={commentMenu}
                  commentableType={commentableType}
                  shareMenu={shareMenu}
                  onReplyClick={onReplyClick}
                />
              )}
            </div>
          </>
        )}
        {isRestricted && restrictedCard}
      </div>
    </div>
  );
};

export default CommentItem;
