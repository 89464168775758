import React from 'react';
import {
  likeButtonTheme,
  ButtonShapeTheme,
  ButtonSizeTheme,
} from './ButtonTheme';
import { ButtonProps } from './index';
import * as S from './styles';
import Text from '../text';

interface LikeButtonProps extends ButtonProps {
  isSelected: boolean;
  count: number;
  customPrefix: React.ReactNode;
}

const LikeButton = React.forwardRef<HTMLButtonElement, LikeButtonProps>(
  (props, ref) => {
    const {
      onClick,
      disabled,
      isSelected,
      count = 0,
      customPrefix,
      size = 'md',
      variant: variantProps,
      ...otherProps
    } = props;

    const variantTheme = likeButtonTheme(isSelected);
    const borderRadius = ButtonShapeTheme.pill;
    const sizeTheme = ButtonSizeTheme[size];

    return (
      <S.StyledButton
        borderRadius={borderRadius}
        size={sizeTheme}
        variant={variantTheme}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        {...otherProps}
      >
        <div className="flex gap-1 items-center">
          {customPrefix || (
            <span role="img" aria-label="like">
              👍
            </span>
          )}
          <Text variant="titlemd" intent="success">
            {count}
          </Text>
        </div>
      </S.StyledButton>
    );
  },
);

export default LikeButton;
