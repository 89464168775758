import React from 'react';
import { Post } from 'entity/post/postTypes';

interface IPostCommentContext {
  canCommentAsAnon: boolean;
  isPromoGroup: boolean;
  isParentQuestion: boolean;
  postLink: string;
  postId: number;
}

export const PostCommentContext = React.createContext<IPostCommentContext>({
  canCommentAsAnon: false,
  isPromoGroup: false,
  isParentQuestion: false,
  postLink: '',
  postId: null,
});

interface OwnProps {
  children: React.ReactNode;
  post: Post;
}

const PostCommentProvider = (props: OwnProps) => {
  const { post } = props;

  const canCommentAsAnon = post.createdByCurrentUser && post.isAnonymous;
  const isPromoGroup = post.group?.slug === 'promo-codes';
  const isParentQuestion = post.isMerged && post.isParent;
  const postLink = `/posts/${post.slug}`;

  return (
    <PostCommentContext.Provider
      value={{
        canCommentAsAnon,
        isPromoGroup,
        isParentQuestion,
        postLink,
        postId: post.id,
      }}
    >
      {props.children}
    </PostCommentContext.Provider>
  );
};

export default PostCommentProvider;
