import { useEffect, useState, useCallback } from 'react';

const useImageZoom = ({ onZoom, onUnzoom }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (isZoomed) {
      if (onZoom) {
        onZoom();
      }
      document.body.style.overflow = 'hidden';
    } else {
      if (onUnzoom) {
        onUnzoom();
      }
      document.body.style.overflow = 'scroll';
    }
  }, [isZoomed, onUnzoom, onZoom]);

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  return {
    isZoomed,
    handleZoomChange,
  };
};

export default useImageZoom;
