import React from 'react';

import { ProfileSelect } from 'app/seedly-component-library';
import { useSelector, useDispatch } from 'react-redux';
import ProfileActions from 'app/reducer/profileRedux';
import { getBusinessAccounts } from 'app/selectors/authSelectors';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

const PROFILE_SECTION_TITLE = {
  personal: 'Personal',
  pro: 'Pro Account',
  basic: 'Basic Account',
  lite: 'Lite Account',
};

const makeProfilesOptions = (profiles = []) => {
  const dispatch = useDispatch();

  const profileGroups = {
    personal: [],
    pro: [],
    basic: [],
    lite: [],
  };

  const profilesOptions = profiles.map(profile => {
    const { image, name, picture, currentPlan, productItems, id } = profile;
    const productName = get(productItems, '[0].name', '');

    return {
      id,
      name,
      secondaryName: productName,
      currentPlan: currentPlan || 'personal',
      image: image || picture,
      onSelect: () =>
        dispatch(ProfileActions.setBusinessProfile({ ...profile })),
    };
  });

  // Partition all business profiles according to their plan
  const profilesGroupOptions = sortBy(profilesOptions, [
    'secondaryName',
  ]).reduce((groups, profile) => {
    if (groups[profile.currentPlan]) {
      if (groups[profile.currentPlan].length === 0) {
        profile.sectionTitle = PROFILE_SECTION_TITLE[profile.currentPlan];
      }
      groups[profile.currentPlan].push(profile);
    }
    return groups;
  }, profileGroups);

  return [
    ...profilesGroupOptions.personal,
    ...profilesGroupOptions.pro,
    ...profilesGroupOptions.basic,
    ...profilesGroupOptions.lite,
  ];
};

const ProfileSelectWrapper = ({ productId, anonType = '' }) => {
  const userProfile = useSelector(state => state.profile.user);
  const businessAccounts = useSelector(getBusinessAccounts);

  let filteredAccounts = businessAccounts.filter(
    account => account.state === 'active',
  );
  if (productId) {
    // should only show business profiles tied to the product
    filteredAccounts = businessAccounts.filter(account =>
      account.productItems.find(product => product.id === productId),
    );
  }

  const profileOptions = makeProfilesOptions([
    userProfile,
    ...filteredAccounts,
  ]);

  const selectedProfile = useSelector(state => state.profile.selectedProfile);

  if (!selectedProfile.id) return null;

  return (
    <ProfileSelect
      anonType={anonType}
      defaultSelectedItem={{
        id: selectedProfile.id,
        name: selectedProfile.name,
        image: selectedProfile.image || selectedProfile.picture,
      }}
      items={profileOptions}
      handleSelectedItemchange={item => {
        item.selectedItem.onSelect();
      }}
      menuAlign="left"
    />
  );
};

export default ProfileSelectWrapper;
