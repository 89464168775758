import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CommentableType } from 'entity/comment/commentTypes';
import PostCommentForm from 'components/comment/PostCommentForm';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { getCommentsRequest } from 'entity/comment/commentRedux';
import { TextEditorContext } from 'components/post/TextEditor/TextEditorContext';
import ChildCommentList from './ChildCommentList';
import CommentItem, { OwnProps as CommentItemProps } from '../CommentItem';

interface OwnProps extends CommentItemProps {
  childCommentableType: CommentableType;
  highlightReplies?: boolean;
}

const CommentItemWithChild = (props: OwnProps) => {
  const { highlightReplies } = props;

  const [isReplyCommentOpen, setReplyComment] = useState(false);

  const { commentsCount } = props.comment;

  const dispatch = useDispatch();
  const router = useRouter();
  const { rid } = router.query;

  const onReplyClick = useRef(() => {});
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (highlightReplies && ref.current && !rid) {
      const offsetTop = ref.current.offsetTop - 150;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  }, [highlightReplies]);

  return (
    <>
      <CommentItem
        {...props}
        onReplyClick={() => {
          setReplyComment(true);
          onReplyClick.current();
        }}
      />
      {isReplyCommentOpen ? (
        <div className="pl-10 w-full rounded">
          <div className="flex flex-col gap-4 w-full items-center pl-2 border-solid border-l-[1px] border-neutral-400">
            <TextEditorContext.Provider
              value={{
                onEditorClickFromOutside: onReplyClick,
                focusTextEditorOnRender: true,
              }}
            >
              <ChildCommentList
                commentableId={props.comment.id}
                totalCommentCount={props.comment.commentsCount}
                commentableType={props.childCommentableType}
                commentShareLink={props.commentShareLink}
              />
              <PostCommentForm
                commentableType={props.childCommentableType}
                commentableId={props.comment.id}
              />
            </TextEditorContext.Provider>
          </div>
        </div>
      ) : (
        commentsCount > 0 &&
        !props.isRestricted && (
          <div
            ref={ref}
            className={clsx(
              'pl-10 w-full rounded',
              highlightReplies && 'bg-blue-100',
            )}
          >
            <div className="flex items-center pl-2 border-solid border-l-[1px] border-neutral-400">
              <span
                className="text-xs font-bold text-neutral-500 hover:text-blue-500 cursor-pointer"
                onClick={() => {
                  dispatch(
                    getCommentsRequest({
                      commentableType: props.childCommentableType,
                      commentableId: props.comment.id,
                      page: 1,
                      per: 20,
                      // sort from oldest to newest in child comment
                      sort: {
                        by: 'updated_at',
                        dir: 'asc',
                      },
                    }),
                  );
                  setReplyComment(true);
                }}
              >
                View {commentsCount} replies
              </span>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default CommentItemWithChild;
