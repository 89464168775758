import clsx from 'clsx';
import React from 'react';
import TruncateMarkup from 'react-truncate-markup';
import styled from 'styled-components';
import Markdown from './index';

const removeBlankLine = markdown => {
  if (!markdown) return '';
  return markdown.replace(/(\n\n\u200b){2,}/g, `\n\n\u200b`);
};

const markdownToPlainText = markdown =>
  markdown
    .replace(/[\n|\r|*]/g, ' ')
    .replace(/(\n\n\u200b){2,}/g, `\n\n\u200b`)
    .replace(/[\u200B-\u200D\uFEFF]/g, ' ')
    .replace(/\+{2}(.*?)\+{2}/g, `$1`) // remove underline
    .replace(/_(.*?)_/g, `$1`) // remove italic
    .replace(/(\(http.*?)\)/g, '')
    .replace(/[*\][]/g, '') // remove links
    .replace(/#{1,}\s/g, '') // remove heading #
    .replace(/&quot;/g, `"`) // convert &quot;
    .replace(/&amp;/g, `&`) // convert &amp;
    .replace(/<[^>]*>(.*?)<\/[^>]*>/g, `$1`) // strip html tags
    .split(/\s{1,}/g)
    .join(' ')
    .trim();

const ReadMoreButton = styled.span`
  cursor: pointer;
  color: ${props => props.theme.colors.seedlyBlue};

  &:hover {
    text-decoration: underline;
  }
`;

const MarkdownTruncate = props => {
  const {
    text,
    extendedClass,
    shouldTruncate = false,
    toogleTruncate = () => {},
    onReadMoreClick = () => {},
    // truncate after number of lines
    truncateLine = 3,
  } = props;

  return (
    <>
      {shouldTruncate ? (
        <TruncateMarkup
          lines={truncateLine}
          ellipsis={
            <span>
              ....{' '}
              <ReadMoreButton
                onClick={() => {
                  toogleTruncate(false);
                  onReadMoreClick();
                }}
              >
                (more)
              </ReadMoreButton>
            </span>
          }
        >
          <div className={clsx('w-full', extendedClass)}>
            {markdownToPlainText(text)}
          </div>
        </TruncateMarkup>
      ) : (
        <Markdown
          source={removeBlankLine(text)}
          extendedClass={extendedClass}
        />
      )}
    </>
  );
};

export default MarkdownTruncate;
