import React from 'react';
import Link from 'components/misc/Link';
import TruncateMarkup from 'react-truncate-markup';

interface OwnProps {
  question: {
    slug: string;
    title: string;
  };
}

const OriginalPostLink = (props: OwnProps) => {
  const { question } = props;

  return (
    <div className="pl-4 border-l-2 border-solid border-l-neutral-400">
      <Link href={`/posts/${question?.slug}`} as={`/posts/${question?.slug}`}>
        <a>
          <span className="text-xs text-neutral-500">Original Post:</span>
          <TruncateMarkup lines={2}>
            <p className="text-xs text-neutral-500">{question?.title}</p>
          </TruncateMarkup>
        </a>
      </Link>
    </div>
  );
};

export default OriginalPostLink;
