import React from 'react';
import Link from 'app/components/misc/Link';
import Avatar from 'app/seedly-component-library/avatar-new';
import useIsMobile from 'utils/hooks/useIsMobile';
import { profileImage } from 'theme';
import clsx from 'clsx';

const formatTitle = (title, maxChar) => {
  if (!title) return '';
  if (title.length <= maxChar) return title;
  return `${title.substring(0, maxChar)}...`;
};

const profileImages = {
  question: { img: profileImage.anonymousPoster, name: 'Question Poster' },
  answer: { img: profileImage.anonymousAnswerer, name: 'Question Poster' },
  default: { img: profileImage.defaultImage, name: 'Default Profile' },
};

const UserPassportBase = props => {
  const {
    imgSrc,
    name,
    credentialText,
    profileSlug,
    createdAtTitle,
    anonType,
    badge,
    verifiedBadge,
    avatarSize = 'avatar-8',
    postedGroup,
    joinGroupButton,
  } = props;

  const isMobile = useIsMobile();

  const formattedName = isMobile ? formatTitle(name, 40) : name;
  const formattedCredential = isMobile
    ? formatTitle(credentialText, 48)
    : credentialText;

  return (
    <div className="flex items-center gap-2">
      <Avatar
        src={anonType ? profileImages[anonType].img : imgSrc}
        sizeClass={avatarSize}
        name={name}
        badge={badge}
      />
      <div className="flex flex-col items-start">
        {profileSlug ? (
          <Link href={`/profile/${profileSlug}`} as={`/profile/${profileSlug}`}>
            <a aria-label={`${name}'s profile`}>
              <div className="flex items-baseline">
                <p
                  className={clsx(
                    avatarSize === 'avatar-12'
                      ? 'text-sm font-bold'
                      : 'text-xs font-bold',
                  )}
                >
                  {formattedName}
                </p>
                {avatarSize !== 'avatar-12' && (
                  <p className="text-xs text-neutral-500 pl-2">
                    {createdAtTitle}
                  </p>
                )}
              </div>
            </a>
          </Link>
        ) : (
          <div className="flex items-center">
            <p className="text-xs font-bold">{formattedName}</p>
            {verifiedBadge}
            <p className="text-xs text-neutral-500 pl-2">{createdAtTitle}</p>
          </div>
        )}
        <p>
          {credentialText && (
            <span className="text-xs text-neutral-500">
              {formattedCredential}
            </span>
          )}
        </p>
        {avatarSize === 'avatar-12' && (
          <div className="flex items-center gap-1">
            <p className="text-xs text-neutral-500">{createdAtTitle}</p>
            {postedGroup && (
              <>
                <p className="text-xs text-neutral-500">∙</p>
                <Link href={`/community/${postedGroup.slug}`}>
                  <a className="text-xs text-neutral-500 hover:text-blue-500">
                    {postedGroup.name}
                  </a>
                </Link>
              </>
            )}
            {joinGroupButton}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserPassportBase;
