import React, { useState } from 'react';
import { X as CloseButton } from 'react-feather';

const PostEncouragement = (props: { name: string; type: string }) => {
  const { name, type } = props;

  const [isOpen, setIsOpen] = useState(true);

  const title = `🎉 Encourage ${name} by liking their first ${type}!`;

  if (!isOpen) return null;

  return (
    <div className="card px-3 py-2 bg-green-100">
      <div className="flex w-full items-center justify-between">
        <p>{title}</p>
        <CloseButton
          size={16}
          onClick={() => setIsOpen(false)}
          className="min-h-[16px] min-w-[16px] cursor-pointer"
        />
      </div>
    </div>
  );
};

export default PostEncouragement;
